import {imageUrl} from 'common/sanity';
import {SeoHeaderProps} from 'common/types';
import {NextSeo} from 'next-seo';
import {FC} from 'react';

const SeoHeader: FC<SeoHeaderProps> = props => {
  return (
    <NextSeo
      title={`${props.title} | Löchle. Ferienhütte im Bregenzerwald.`}
      description={props.description}
      canonical={`${props.host}/${props.slug?.replace('index', '')}`}
      openGraph={{
        type: 'website',
        url: `${props.host}/${props.slug?.replace('index', '')}`,
        title: `${props.title} | Löchle. Ferienhütte im Bregenzerwald.`,
        description: props.description,
        images: [
          {
            url: props.image
              ? imageUrl(props.image)
                  .width(600)
                  .auto('format')
                  .quality(80)
                  .url() || ''
              : '',
          },
        ],
        site_name: 'Löchle.',
      }}
    />
  );
};

export default SeoHeader;
