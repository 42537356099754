import styled from 'styled-components';

const Button = styled.button<{rounded?: boolean; fullwidth?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${({fullwidth}) => fullwidth && `width: 100%;`};
  padding: ${({theme}) => theme.spacing(1)}px ${({theme}) => theme.spacing(2)}px;
  background: ${({theme}) => theme.colors.blue};
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fonts.teko};
  font-size: ${({theme}) => theme.fontSize(1)}px;
  line-height: ${({theme}) => theme.fontSize(1)}px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
  border-radius: ${({rounded}) => (rounded ? `5px` : `0px`)};
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default Button;
