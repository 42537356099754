import {COOKIE_USER_ACCEPTED, COOKIE_USER_ACCEPTED_VALUE, IS_SERVER} from 'common/constants';
import {imageUrl} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {BackgroundImageProps, HeaderProps} from 'common/types';
import Button from 'components/button';
import isbot from 'isbot';
import Link from 'next/link';
import {parseCookies, setCookie} from 'nookies';
import {FC, useEffect, useState} from 'react';
import ProgressiveImage from 'react-progressive-image';
import Typist from 'react-text-typist';
import styled from 'styled-components';
// @ts-ignore
import colorAlpha from 'color-alpha';

const Wrapper = styled.section<{background: string}>`
  height: 100vh;
  height: var(--vh, 100vh);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: ${({background}) => background};
`;

interface ImageProp {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

const BackgroundImageElement = styled.div<{
  image: ImageProp;
}>`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }
  overflow: hidden;
  background-image: ${({image}) => image && `url(${image.xs})`};
  ${breakpoint.size('sm')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.sm})`};
  `}
  ${breakpoint.size('md')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.md})`};
  `}
  ${breakpoint.size('lg')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.lg})`};
  `}
  ${breakpoint.size('xl')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.xl})`};
  `}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    z-index: 2;
    pointer-events: none;
  }
`;

const BackgroundImageOverlay = styled.div`
  height: 100%;
  width: 100%;
  z-index: 4;
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  padding: 0 ${({theme}) => theme.spacing(1)}px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.3) 0.01%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const BackgroundImage: FC<BackgroundImageProps> = ({placeholder, large, children, background}) => (
  <Wrapper background={background}>
    <ProgressiveImage src={large || ''} placeholder={placeholder || ''}>
      {(src: string) => (
        <BackgroundImageElement
          image={{
            xs: src.replace('w=1600', 'w=800'),
            sm: src.replace('w=1600', 'w=1000'),
            md: src.replace('w=1600', 'w=1200'),
            lg: src.replace('w=1600', 'w=1400'),
            xl: src.replace('w=1600', 'w=1600'),
          }}
        />
      )}
    </ProgressiveImage>
    <BackgroundImageOverlay>{children}</BackgroundImageOverlay>
  </Wrapper>
);

const CTABox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({theme}) => theme.colors.white};
  ${breakpoint.size('sm')`
    flex-direction: row;
  `}
`;

const CtaText = styled.div`
  flex: 1;
  font-size: ${({theme}) => theme.fontSize(0.8)}px;
  padding: ${({theme}) => theme.spacing(0.8)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1)}px;  
    padding: ${({theme}) => theme.spacing(1)}px;
  `}
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Title = styled.h1`
  color: ${({theme}) => theme.colors.white};
  font-size: ${({theme}) => theme.fontSize(1.75)}px;
  margin-bottom: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(2.5)}px;
    margin-bottom: ${({theme}) => theme.spacing(1.5)}px;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(4)}px;
    margin-bottom: ${({theme}) => theme.spacing(2)}px;
  `}
`;

const CookieBanner = styled.div`
  width: calc(100% + ${({theme}) => theme.spacing(2)}px);
  margin-left: -${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    width: 100%;
    margin: 0;
  `};
  background: ${({theme}) => theme.colors.lightblue};
  padding: ${({theme}) => theme.spacing(1)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CookieText = styled.div`
  flex: 1;
  font-size: ${({theme}) => theme.fontSize(0.7)}px;
  margin-right: ${({theme}) => theme.spacing(0.5)}px;
  & a {
    text-transform: none;
    text-decoration: none;
    z-index: 1;
    color: ${({theme}) => theme.colors.black};
    font-family: ${({theme}) => theme.fonts.teko};
    margin-bottom: 0.5em;
    font-size: ${({theme}) => theme.fontSize(0.9)}px;
    ${breakpoint.size('sm')`
        font-size: ${({theme}) => theme.fontSize(0.9)}px;
      `}
    position: relative;
    cursor: pointer;
    &:hover {
      &:after {
        opacity: 0.8;
        right: 0;
      }
    }
    &:after {
      transition: opacity 400ms ease-in-out, right 200ms ease-in-out;
      content: '';
      display: inline-block;
      opacity: 0;
      height: 40%;
      overflow: hidden;
      background: ${({theme}) => theme.colors.blue};
      position: absolute;
      right: 100%;
      left: 0;
      bottom: 10%;
      z-index: -1;
      margin-left: -5px;
      margin-right: -5px;
    }
  }
`;

const CookieButton = styled(Button)`
  padding: ${({theme}) => theme.spacing(0.5)}px ${({theme}) => theme.spacing(1.5)}px;
`;

const TypistTitle: FC<HeaderProps> = props => {
  if (props.title && props.title2 && props.title3) {
    return (
      <Typist
        showCursor={false}
        sentences={[props.title, props.title2, props.title3]}
        typingSpeed={350}
        deletingSpeed={175}
        pauseTime={400}
        loop={true}
      />
    );
  }
  if (props.title && props.title2) {
    return (
      <Typist
        showCursor={false}
        sentences={[props.title, props.title2]}
        typingSpeed={350}
        deletingSpeed={175}
        pauseTime={400}
        loop={true}
      />
    );
  }
  return <span>{props.title}</span>;
};

const Header: FC<HeaderProps> = props => {
  const [accepted, setAccepted] = useState<boolean | null>(null);
  const accept = () => {
    setCookie(null, COOKIE_USER_ACCEPTED, COOKIE_USER_ACCEPTED_VALUE, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
    });
    setAccepted(true);
  };
  useEffect(() => {
    if (IS_SERVER) return;
    if (isbot(navigator.userAgent)) return;
    const cookies = parseCookies();
    const cookieAccepted = cookies[COOKIE_USER_ACCEPTED] === COOKIE_USER_ACCEPTED_VALUE;
    setAccepted(cookieAccepted);
  }, []);
  return (
    <BackgroundImage
      background={colorAlpha(props.image.dominant.background, 0.4)}
      large={
        imageUrl(props.image)
          .width(1600)
          .auto('format')
          .quality(80)
          .url() || ''
      }
      placeholder={props.image.lqip}>
      <Content>
        <Title>
          <TypistTitle {...props} />
        </Title>
        <CTABox>
          <CtaText>{props.cta}</CtaText>
          <Link href={props.ctaLink.slug} passHref>
            <Button as='a'>{props.ctaLink.title}</Button>
          </Link>
        </CTABox>
        {!(accepted || accepted === null) && (
          <CookieBanner>
            <CookieText>
              Diese Seite verwendet Cookies. Wenn Sie weiterhin auf der Website surfen, stimmen Sie der Verwendung von
              Cookies zu.{' '}
              <Link passHref href={'/datenschutz'}>
                <a>Datenschutz</a>
              </Link>
            </CookieText>
            <CookieButton onClick={() => accept()}>Ok</CookieButton>
          </CookieBanner>
        )}
      </Content>
    </BackgroundImage>
  );
};

export default Header;
