import BlockContentReact, {BlockContentProps} from '@sanity/block-content-to-react';
import {FC} from 'react';
import styled from 'styled-components';
import {sanity} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {
  DaysApiResponse,
  PricesApiResponse,
  SanityAccordionFormData,
  SanityBlockContentProps,
  SanityBookingCalendarData,
  SanityBookingFormData,
  SanityDividerData,
  SanityGalleryData,
  SanityImageContentData,
  SanityImageSliderData,
  SanityPageCardGridData,
  SanityReviewSliderData,
} from 'common/types';
import dynamic from 'next/dynamic';
const Accordion = dynamic(() => import('components/accordion'));
const BookingCalendar = dynamic(() => import('components/bookingCalendar'));
const BookingForm = dynamic(() => import('components/bookingForm'));
const Divider = dynamic(() => import('components/divider'));
const Gallery = dynamic(() => import('components/gallery'));
const ImageContent = dynamic(() => import('components/imageContent'));
const ImageSlider = dynamic(() => import('components/imageSlider'));
const PageCardGrid = dynamic(() => import('components/pageCardGrid'));
const ReviewSlider = dynamic(() => import('components/reviewSlider'));

export const SanityWrapper = styled.div`
  width: 100%;
  margin-top: ${({theme}) => theme.fontSize(1.5)}px;
  margin-bottom: ${({theme}) => theme.fontSize(1.5)}px;
  ${breakpoint.size('sm')`
      margin-top: ${({theme}) => theme.fontSize(2.5)}px;
      margin-bottom: ${({theme}) => theme.fontSize(2.5)}px;
  `}
  ${breakpoint.size('md')`
      margin-top: ${({theme}) => theme.fontSize(4)}px;
      margin-bottom: ${({theme}) => theme.fontSize(4)}px;
  `}
  & > div > div {
    & > h1,
    & > h2,
    & > p,
    & > ul {
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 ${({theme}) => theme.spacing(1)}px;
      ${breakpoint.size('sm')`
        padding: 0 ${({theme}) => theme.spacing(2)}px;
      `}
      ${breakpoint.size('md')`
          padding: 0 ${({theme}) => theme.spacing(6)}px 0 ${({theme}) => theme.spacing(2)}px;
      `}
      ${breakpoint.size('lg')`
          padding: 0 ${({theme}) => theme.spacing(14)}px 0 ${({theme}) => theme.spacing(2)}px;
      `}
    }
    & > ul {
      padding-left: ${({theme}) => theme.spacing(1 + 1.5)}px;
      ${breakpoint.size('sm')`
        padding-left: ${({theme}) => theme.spacing(2 + 1.5)}px;
      `}
    }
  }
`;

const Sanity = styled.div`
  & h1,
  & h2 {
    margin-top: 0.75em;
    margin-bottom: 0.5em;
    line-height: 1.1em;
  }
  & p,
  & ul {
    font-size: ${({theme}) => theme.fontSize(0.8)}px;
    line-height: 1.35em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    ${breakpoint.size('sm')`
        font-size: ${({theme}) => theme.fontSize(1)}px;
      `}
  }
  & a[target='_blank'] {
    text-transform: none;
    text-decoration: none;
    z-index: 1;
    color: ${({theme}) => theme.colors.black};
    font-family: ${({theme}) => theme.fonts.teko};
    margin-bottom: 0.5em;
    font-size: ${({theme}) => theme.fontSize(1.15)}px;
    ${breakpoint.size('sm')`
        font-size: ${({theme}) => theme.fontSize(1.35)}px;
      `}
    position: relative;
    cursor: pointer;
    &:hover {
      &:after {
        opacity: 0.8;
        right: 0;
      }
    }
    &:after {
      transition: opacity 400ms ease-in-out, right 200ms ease-in-out;
      content: '';
      display: inline-block;
      opacity: 0;
      height: 40%;
      overflow: hidden;
      background: ${({theme}) => theme.colors.blue};
      position: absolute;
      right: 100%;
      left: 0;
      bottom: 10%;
      z-index: -1;
      margin-left: -5px;
      margin-right: -5px;
    }
  }
`;

interface SerializerProps {
  days?: DaysApiResponse;
  prices?: PricesApiResponse;
}

const serializers = ({days, prices}: SerializerProps): BlockContentProps['serializers'] => ({
  types: {
    divider: (props: {node: SanityDividerData}) => <Divider {...props.node} />,
    imageSlider: (props: {node: SanityImageSliderData}) => <ImageSlider {...props.node} />,
    reviewSlider: (props: {node: SanityReviewSliderData}) => <ReviewSlider {...props.node} />,
    imageContent: (props: {node: SanityImageContentData}) => <ImageContent {...props.node} />,
    bookingCalendar: (props: {node: SanityBookingCalendarData}) => <BookingCalendar {...props.node} days={days} />,
    bookingForm: (props: {node: SanityBookingFormData}) => <BookingForm {...props.node} days={days} prices={prices} />,
    accordion: (props: {node: SanityAccordionFormData}) => <Accordion {...props.node} />,
    pageCardGrid: (props: {node: SanityPageCardGridData}) => <PageCardGrid {...props.node} />,
    gallery: (props: {node: SanityGalleryData}) => <Gallery {...props.node} />,
  },
  marks: {
    link: ({mark, children}) => {
      const {href} = mark;
      return (
        <a href={href} target='_blank' rel='noreferrer'>
          {children}
        </a>
      );
    },
  },
});

const SanityBlockContent: FC<SanityBlockContentProps> = ({blocks, days, prices}) => {
  return (
    <Sanity>
      <BlockContentReact
        projectId={sanity.config().projectId}
        dataset={sanity.config().dataset}
        imageOptions={{height: 800}}
        blocks={blocks}
        serializers={serializers({days, prices})}
      />
    </Sanity>
  );
};

export default SanityBlockContent;
