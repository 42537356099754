import client from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import {SanityImageSource} from '@sanity/image-url/lib/types/types';

export const sanity = client({
  projectId: 'cfuddob9',
  dataset: 'production',
  useCdn: false,
});

export const imageUrl = (source: SanityImageSource) => imageUrlBuilder(sanity).image(source);
